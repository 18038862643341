<template>
  <div class="home">
    <h2>Impressum</h2>
    <br/>
    Dies ist eine private Website von:<br/>
    <br/>
    Björn Mönikes<br/>
    Kinkelstraße 10<br/>
    44263 Dortmund<br/>
    E-Mail: bmoenikes@gmx.net<br/>
  </div>
</template>

<script>
export default {
  name: 'Imprint',
}
</script>
